import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { colors, fonts, weights, mediaQueries, container } from "../../styles"

const linkList = [
  { route: "/", label: "トップページ" },
  { route: "/service/", label: "製品 / サービス" },
  { route: "/about/", label: "会社案内" },
  { route: "/careers/", label: "採用情報" },
  { route: "/access/", label: "アクセス" },
  { route: "/contact/", label: "お問い合わせ" },
  { route: "/legal/", label: "個人情報保護方針" },
]

const Footer = () => {
  const linkStyle = css`
    display: block;
    color: ${colors.whiteFaded};
    font-family: ${fonts.sans};
    padding: 13px;
    font-size: 18px;
    line-height: 1;
    font-weight: ${weights.bold};

    ${mediaQueries.phoneLarge} {
      margin-right: 40px;
      padding: 11px 0;
    }
    &:hover,
    &:focus {
      color: ${colors.white};
    }
  `
  const wrapperStyle = css`
    padding: 20px 0;
    text-align: center;
    background-color: ${colors.darkgray};
    min-height: 100vh;
    display: flex;
    align-items: center;

    ${mediaQueries.phoneLarge} {
      display: block;
      padding: 80px 0;
      min-height: 0;
    }
  `
  const innerWrapperStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50vh;
    ${mediaQueries.phoneLarge} {
      flex-direction: row;
      justify-content: center;
      min-height: 0;
    }
  `
  return (
    <div css={wrapperStyle}>
      <div css={[innerWrapperStyle, container.max]}>
        {linkList.map(i => (
          <Link key={i.route} css={linkStyle} to={i.route}>
            {i.label}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Footer
