import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Menu from "../Menu"
import { colors, mediaQueries, container } from "../../styles"

import ThirdAndGrove from "./svg/ThirdAndGrove"
import Hamburger from "./svg/Hamburger"

const TopNav = ({ fill }) => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!isOpen)

  return (
    <>
      <div
        css={[
          container.max,
          css`
            position: ${isOpen ? "fixed" : "absolute"};
            top: 0;
            padding-top: 20px;
            padding-bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 4;

            ${mediaQueries.phoneLarge} {
              padding-top: 30px;
            }
          `,
        ]}
      >
        <Link to="/" aria-label="return to homepage" data-cy="homeButton">
          {/* This guard keeps the Gatsby build from breaking by ensuring this code isn't run at build time. */}
          <ThirdAndGrove
            css={css`
              height: 22px;
              color: ${isOpen ? colors.lightgray : fill};
            `}
          />
        </Link>
        <button
          css={css`
            background-color: transparent;
            padding: 0;
            margin: 0;
            border: none;
            min-height: 25px;
            cursor: pointer;
            :focus {
              outline: none;
            }
          `}
          type="button"
          onClick={() => toggleOpen()}
          data-cy="menuButton"
          aria-label="open site menu"
        >
          <Hamburger fill={isOpen ? colors.lightgray : fill} isOpen={isOpen} />
        </button>
      </div>
      <Menu toggleOpen={toggleOpen} menuOpen={isOpen} />
    </>
  )
}

TopNav.propTypes = {
  fill: PropTypes.string,
}

TopNav.defaultProps = {
  fill: colors.lightgray,
}

export default TopNav
