import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { colors, mediaQueries, weights, container } from "../../styles"

const Menu = ({ menuOpen, toggleOpen }) => {
  const textFadeIn = css`
    position: relative;
    opacity: ${menuOpen ? "1" : "0"};
    transform: translateY(${menuOpen ? "0" : "50%"});
    transition-property: color, transform, opacity;
    transition-timing-function: ease-out;
    transition-duration: 0.5s;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: ${colors.darkgray};
      transition: inherit;
      height: ${menuOpen ? "0" : "100%"};
    }
  `

  const mobileDelay1 = css`
    transition-delay: 0s, 0.1s, 0.1s;
  `

  const mobileDelay2 = css`
    transition-delay: 0s, 0.2s, 0.2s;
  `

  const mobileDelay3 = css`
    transition-delay: 0s, 0.3s, 0.3s;
  `

  const mobileDelay4 = css`
    transition-delay: 0s, 0.4s, 0.4s;
  `

  const mobileDelay5 = css`
    transition-delay: 0s, 0.5s, 0.5s;
  `

  const mobileDelay6 = css`
    transition-delay: 0s, 0.6s, 0.6s;
  `

  const desktopDelay1 = css`
    ${mediaQueries.phoneLarge} {
      transition-delay: 0s, 0.2s, 0.2s;
    }
  `

  const desktopDelay2 = css`
    ${mediaQueries.phoneLarge} {
      transition-delay: 0s, 0.4s, 0.4s;
    }
  `

  const desktopDelay3 = css`
    ${mediaQueries.phoneLarge} {
      transition-delay: 0s, 0.6s, 0.6s;
    }
  `

  const linkBaseStyles = css`
    display: block;
    color: ${colors.whiteFaded};
    font-weight: ${weights.medium};
    letter-spacing: -0.2px;
    text-align: center;

    &:hover,
    &:focus {
      color: ${colors.white};
    }
  `

  const linkPrimaryStyle = css`
    font-size: 36px;
    line-height: 87px;

    ${mediaQueries.desktop} {
      font-size: 48px;
      line-height: 111px;
    }
  `

  const sectionStyle = css`
    ${mediaQueries.phoneLarge} {
      padding: 0 40px;
    }
  `

  const sectionPrimaryStyle = css`
    h5 {
      display: none;

      ${mediaQueries.phoneLarge} {
        display: block;
      }
    }
  `

  return (
    <nav
      css={css`
        position: fixed;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: ${colors.darkgray};
        transition: 0.3s ease all;
        overflow: hidden;
        z-index: 3;
        top: ${menuOpen ? "0" : "100vh"};
        flex-direction: column;
        justify-content: center;
        height: ${menuOpen ? "auto" : "0"};
        min-height: ${menuOpen ? "100vh" : "0"};
        padding: 0;

        ${mediaQueries.phoneLarge} {
          flex-direction: row;
          height: ${menuOpen ? "100vh" : "0"};
        }
      `}
    >
      <div
        css={[
          container.max,
          css`
            padding-top: 80px;
            padding-bottom: 60px;
            ${mediaQueries.phoneLarge} {
              display: flex;
              justify-content: center;
              padding-top: 0;
              padding-bottom: 0;
            }
          `,
        ]}
      >
        <section css={[sectionStyle, sectionPrimaryStyle]}>
          <Link
            onClick={() => toggleOpen()}
            css={[
              linkPrimaryStyle,
              textFadeIn,
              linkBaseStyles,
              mobileDelay1,
              desktopDelay1,
            ]}
            to="/service/"
          >
            製品 / サービス
          </Link>
          <Link
            css={[
              linkPrimaryStyle,
              textFadeIn,
              linkBaseStyles,
              mobileDelay2,
              desktopDelay2,
            ]}
            onClick={() => toggleOpen()}
            to="/about/"
          >
            会社案内
          </Link>
          <Link
            css={[
              linkPrimaryStyle,
              textFadeIn,
              linkBaseStyles,
              mobileDelay3,
              desktopDelay3,
            ]}
            onClick={() => toggleOpen()}
            to="/legal/"
          >
            個人情報保護方針
          </Link>
        </section>

        <section css={[sectionStyle, sectionPrimaryStyle]}>
          <Link
            css={[
              linkPrimaryStyle,
              textFadeIn,
              linkBaseStyles,
              mobileDelay4,
              desktopDelay1,
            ]}
            onClick={() => toggleOpen()}
            to="/careers/"
          >
            採用情報
          </Link>
          <Link
            css={[
              linkPrimaryStyle,
              textFadeIn,
              linkBaseStyles,
              mobileDelay5,
              desktopDelay2,
            ]}
            onClick={() => toggleOpen()}
            to="/access/"
          >
            アクセス
          </Link>
          <Link
            css={[
              linkPrimaryStyle,
              textFadeIn,
              linkBaseStyles,
              mobileDelay6,
              desktopDelay3,
            ]}
            onClick={() => toggleOpen()}
            to="/contact/"
          >
            お問い合わせ
          </Link>
        </section>
      </div>
    </nav>
  )
}

Menu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
}

export default Menu
