/* eslint-disable import/prefer-default-export */
/* Variables and functions specifically for CSS-in-JS use */

/* Media queries */
const breakpoints = [480, 900, 1220]
const names = [`xs`, `phoneLarge`, `desktop`]

export const mediaQueries = breakpoints.reduce((acc, bp, i) => {
  acc[names[i]] = `@media (min-width: ${bp}px)`
  return acc
}, {})

export const jsBreakpoints = breakpoints.reduce((acc, bp, i) => {
  acc[names[i]] = bp
  return acc
}, {})

/* Fonts */
export const fonts = {
  serif: `'Sawarabi Mincho', serif`,
  sans: `"Noto Sans", "Noto Sans CJK JP", sans-serif`,
  mono: `monospace`,
}

export const weights = {
  thin: `100`,
  light: `300`,
  regular: `400`,
  medium: `500`,
  bold: `700`,
  black: `900`,
}

/* Colors - Use a RGB to HEX converter */
export const colors = {
  white: `#fff`,
  whiteFaded: `rgba(255, 255, 255, 0.7)`,
  black: `#000`,
  gray: `#e0e0e0`,
  lightgray: `#F8F8F9;`,
  darkgray: `#29292a`,
  darkgrayFaded: `rgba(41, 41, 42, 0.7)`,
  reallydarkgray: `#282829;`,
  yellow: `#EBC900`,
  lightblue: `#a5e6ec`,
  acquiaBlue: `#0678BE`,
  lightgreen: `#cef5e2`,
  gatsbyPurple: "#663399",
  drupalBlue: "#29A8DF",
  shopifyGreen: "#80AB42",
  red: "red",
}
